<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 14:53:20
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-08-23 22:29:07
-->
<template>
  <div>
    <div class="header__img"></div>
    <div class="form">
      <div class="form-row form-row--require" v-if="!newRepair">
        <div class="form__title">门店编码</div>
        <input
          type="text"
          placeholder="请输入门店编码"
          class="input--lock"
          v-model="query.number"
          @blur="inputStoreNum"
          :disabled="!query.type"
        />
        <div
          class="flex-x center between form__none"
          :class="!query.type ? 'form__none--active' : ''"
          @click="hasStoreNum"
        >
          <span>无</span>
          <div></div>
        </div>
      </div>

      <div class="form-row form-row--require">
        <div class="form__title">品牌区域</div>
        <input
          type="text"
          placeholder="输入品牌区域"
          class="input--lock"
          v-model="query.brandAreaName"
          :disabled="query.type || newRepair"
        />
      </div>

      <div class="form-row form-row--require">
        <div class="form__title">店铺名称</div>
        <input
          type="text"
          placeholder="输入店铺名称"
          class="input--lock"
          v-model="query.name"
          :disabled="query.type || newRepair"
        />
      </div>

      <div class="form-row form-row--require">
        <div class="form__title">门店地址</div>
        <input
          type="text"
          placeholder="输入门店地址"
          class="input--lock"
          v-model="query.address"
          :disabled="query.type || newRepair"
        />
      </div>

      <div class="form-row form-row--require">
        <div class="form__title">联系人</div>
        <input
          type="text"
          placeholder="输入联系人"
          v-model="query.contactPerson"
        />
      </div>

      <div class="form-row form-row--require">
        <div class="form__title">联系电话</div>
        <input
          type="text"
          placeholder="输入联系电话"
          v-model="query.contactMobile"
        />
      </div>

      <div class="form-row form-row--require" @click="showTypes = true">
        <div class="form__title">报修类型</div>
        <input
          type="text"
          placeholder="选择报修类型"
          disabled
          :value="
            query.repairType !== '' ? workTypes[query.repairType].name : ''
          "
        />
        <div class="form__arrow"></div>
      </div>

      <div class="form-selecter form-row--require">
        <div class="form__title">报修等级</div>
        <div class="form__selecter">
          <div
            class="form__selecter__item"
            :class="
              index === activeRepairLevels ? 'form__selecter__item--active' : ''
            "
            v-for="(item, index) in repairLevels"
            :key="index"
            @click="activeRepairLevels = index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="form-hightlight">
        注:非紧急报修24小时内响应，48小时内上门维修；紧急
        报修4小时内响应，24小时内上门维修。
      </div>

      <div class="form-selecter form-row--require">
        <div class="form__title">上门维修时间</div>
        <div class="form__selecter">
          <div
            class="form__selecter__item"
            :class="
              index === activeServiceTimeTypes
                ? 'form__selecter__item--active'
                : ''
            "
            v-for="(item, index) in serviceTimeTypes"
            :key="index"
            @click="activeServiceTimeTypes = index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div
        class="form-row form-row--require"
        @click="openTimePicker('serviceTime')"
      >
        <input
          type="text"
          disabled
          placeholder="输入上门维修时间"
          v-model="query.serviceTime"
        />
      </div>

      <div class="form-selecter form-row--require">
        <div class="form__title">勘察时间</div>
        <div class="form__selecter">
          <div
            class="form__selecter__item"
            :class="
              index === activeSurveyTimeTypes
                ? 'form__selecter__item--active'
                : ''
            "
            v-for="(item, index) in surveyTimeTypes"
            :key="index"
            @click="activeSurveyTimeTypes = index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div
        class="form-row form-row--require"
        @click="openTimePicker('surveyTime')"
      >
        <input
          type="text"
          disabled
          placeholder="输入勘察时间"
          v-model="query.surveyTime"
        />
      </div>

      <div class="form-selecter form-row--require">
        <div class="form__title">是否有登高工具</div>
        <div class="form__selecter">
          <div
            class="form__selecter__item"
            :class="
              index === activeHasClimbingTool
                ? 'form__selecter__item--active'
                : ''
            "
            v-for="(item, index) in hasClimbingTool"
            :key="index"
            @click="activeHasClimbingTool = index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="form-row form-row--noline form-row--require">
        <div class="form__title">报修描述</div>
      </div>

      <div class="form-area">
        <textarea
          id=""
          cols="30"
          rows="10"
          maxlength="100"
          v-model="query.description"
        ></textarea>
      </div>

      <div class="form-hightlight">注：一事一报，请不要混一起报</div>

      <div class="form-row form-row--noline">
        <div class="form__title">拍照</div>
      </div>

      <div class="form-imgs">
        <div
          class="form__img form__img--add"
          @click="selectImg"
          v-show="imgsDisplay.length < maxImg"
        ></div>
        <div
          class="img-box form__img"
          v-for="(item, index) in imgsDisplay"
          :key="item"
          @click="$previewImgs(imgsDisplay)"
        >
          <img :src="item" alt="" />
          <div class="form__img__del" @click="delImg(index)"></div>
        </div>
      </div>

      <div class="btn-box">
        <div class="form__btn" @click="submitAndNext">新增报修项</div>
        <div class="form__btn" @click="saveAndQuit">保存并退出</div>
        <div class="form__btn form__btn--confirm" @click="submit">确认提交</div>
      </div>
    </div>

    <div class="mine" @click="$router.replace({ name: 'UserIndex' })"></div>

    <msg-box />

    <popup v-model="showTypes" position="bottom">
      <van-picker
        title="报修类型"
        show-toolbar
        :columns="workTypes"
        value-key="name"
        @cancel="showTypes = false"
        @confirm="selectType"
      />
    </popup>

    <popup v-model="showTimePicker" position="bottom">
      <datetime-picker
        title="选择日期"
        type="date"
        :min-date="new Date(new Date().getTime() + 10 * 60 * 1000)"
        @cancel="showTimePicker = false"
        @confirm="selectDate"
      />
    </popup>

    <popup v-model="showHourPicker" position="bottom">
      <van-picker
        :columns="hourColumn"
        title="选择时间段"
        show-toolbar
        @cancel="showHourPicker = false"
        @confirm="selectTime"
      />
    </popup>

    <msg-box
      v-model="showSuccess"
      :title="'报修工单已经提交成功\n请耐心等待师傅维修'"
      content="工单实时进度可在“我的”进行查询"
      @onclick="$router.replace({ name: 'UserIndex' })"
    />
  </div>
</template> 

<script>
import msgBox from "../../../components/msgBox/msgBox";
import formatDate from "../../../utils/formatDate";
import {
  submit as submitOrder,
  submitDraftBox,
} from "../../../utils/api/workOrder";
import { repairType } from "../../../utils/api/utils";
import { getStoreInfo } from "../../../utils/api/store";
import { Popup, Picker, DatetimePicker, Toast } from "vant";
import { chooseImgs, getDisplayImgs, uploadImgs } from "../../../utils/wxTools";

const getHours = function () {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${i < 10 ? "0" + i : i}:00`);
  }
  return hours;
};

export default {
  components: {
    "msg-box": msgBox,
    popup: Popup,
    "van-picker": Picker,
    "datetime-picker": DatetimePicker,
  },
  data() {
    return {
      workTypes: [], // 工种列表
      showTypes: false, // 显示工种列表
      repairLevels: [
        { id: 0, name: "非紧急" },
        { id: 1, name: "紧急" },
      ], // 报修等级
      activeRepairLevels: 0,
      serviceTimeTypes: [
        { id: 0, name: "营业时间" },
        { id: 1, name: "非营业时间" },
      ], // 上门维修时间
      activeServiceTimeTypes: 0,
      surveyTimeTypes: [
        { id: 0, name: "营业时间" },
        { id: 1, name: "非营业时间" },
      ],
      activeSurveyTimeTypes: 0,
      hasClimbingTool: [
        { id: 1, name: "有" },
        { id: 0, name: "没有" },
      ], // 登高工具
      activeHasClimbingTool: 0,
      showTimePicker: false, // 显示时间选择器
      showHourPicker: false, // 显示小时选择器
      hourColumn: [
        // 小时选择框
        // 第一列
        {
          values: getHours(),
          defaultIndex: 9,
        },
        // 第二列
        {
          values: getHours(),
          defaultIndex: 9,
        },
      ],
      prepareTimeKey: "", // 准备修改的时间key
      prepareTime: "", // 第一次选中的日期
      maxImg: 3, // 最多选中图片
      imgs: [], // 选中的图片
      imgsDisplay: [], // 选中的图片显示
      newRepair: false, // 新增报修项目
      showSuccess: false, // 显示成功
      query: {
        number: "",
        type: true,
        name: "",
        brandAreaName: "",
        address: "",
        contactPerson: "",
        contactMobile: "",
        repairType: "",
        repairLevel: "",
        serviceTimeType: "",
        serviceTime: "",
        surveyTimeType: "",
        surveyTime: "",
        description: "",
        photo: "",
      },
    };
  },

  created() {
    repairType().then((res) => {
      this.workTypes = res.data.data.list;
    });
  },

  methods: {
    // 选择报修类型
    selectType(e, index) {
      this.query.repairType = index;
      this.showTypes = false;
    },

    // 打开时间选择器
    openTimePicker(key) {
      this.prepareTimeKey = key;
      this.showTimePicker = true;
    },

    // 选择日期
    selectDate(e) {
      this.prepareTime = formatDate(e, "yyyy-MM-dd");
      this.showTimePicker = false;
      this.showHourPicker = true;
    },

    // 选择时间
    selectTime(e) {
      this.query[this.prepareTimeKey] = this.prepareTime + " " + e.join("-");
      this.showHourPicker = false;
    },

    // 删除图片
    delImg(index) {
      this.imgsDisplay.splice(index, 1);
      this.imgs.splice(index, 1);
    },

    // 选择图片上传
    async selectImg() {
      const imgs = await chooseImgs(this.maxImg - this.imgs.length);
      const imgsDisplay = await getDisplayImgs(imgs);
      this.imgs.push(...imgs);
      this.imgsDisplay.push(...imgsDisplay);
    },

    // 是否拥有门店编号
    hasStoreNum() {
      this.query.type = !this.query.type;
      this.query.number = "";
      this.query.name = "";
      this.query.brandAreaName = "";
      this.query.address = "";
    },

    // 输入门店编号
    inputStoreNum(e) {
      if (!this.query.number) return false;
      getStoreInfo({
        number: this.query.number,
      }).then((res) => {
        let store = res.data.data[0];
        if (!store) {
          this.query.brandAreaName = "";
          this.query.name = "";
          this.query.address = "";
          this.query.contactPerson = "";
          this.query.contactMobile = "";
        } else {
          this.query.number = store.number;
          this.query.brandAreaName = store.brandAreaName;
          this.query.name = store.shopName;
          this.query.address = store.address;
          this.query.contactPerson = store.principal;
          this.query.contactMobile = store.mobile;
        }
      });
    },

    // 保存
    async save() {
      let query = JSON.parse(JSON.stringify(this.query));
      return new Promise(async (resolve, reject) => {
        if (query.type) if (!query.number) return reject("请输入门店编码");
        if (!query.brandAreaName) return reject("请输入品牌区域");
        if (!query.name) return reject("请输入店铺名称");
        if (!query.address) return reject("请输入门店地址");
        if (!query.contactPerson) return reject("请输入联系人");
        if (!query.contactMobile) return reject("请输入联系电话");
        if (query.repairType === "") return reject("请选择报修类型");
        if (!query.serviceTime) return reject("请输入上门维修时间");
        if (!query.surveyTime) return reject("请输入勘察时间");

        query.repairType = this.workTypes[query.repairType].value;

        query.repairLevel = this.repairLevels[this.activeRepairLevels].id;
        query.serviceTimeType = this.serviceTimeTypes[
          this.activeServiceTimeTypes
        ].id;
        query.surveyTimeType = this.surveyTimeTypes[
          this.activeSurveyTimeTypes
        ].id;
        query.type ? (query.type = 1) : (query.type = 0);

        query.ascend = this.hasClimbingTool[this.activeHasClimbingTool].id;

        query.photo = await uploadImgs(this.imgs).catch((res) => {
          reject(res.data.msg);
        });

        query.photo.length === 0
          ? (query.photo = "")
          : (query.photo = JSON.stringify(query.photo));

        submitDraftBox({
          ...query,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((res) => {
            reject(res.data.msg);
          });
      });
    },

    // 提交订单
    submitOrder(id) {
      return new Promise((resolve, reject) => {
        submitOrder({
          draftBoxIds: JSON.stringify([id]),
        })
          .then((res) => {
            resolve();
          })
          .catch((res) => {
            Toast(res.data.msg);
            reject();
          });
      });
    },

    // 保存并退出
    saveAndQuit() {
      this.save()
        .then((res) => {
          this.$router.back();
        })
        .catch((res) => {
          Toast(res);
        });
    },

    // 提交工单
    submit() {
      this.save()
        .then((res) => {
          let id = res.data.data.id;
          this.submitOrder(id).then(() => {
            this.showSuccess = true;
          });
        })
        .catch((res) => {
          Toast(res);
        });
    },

    // 提交后重新报修
    submitAndNext() {
      this.save()
        .then((res) => {
          let id = res.data.data.id;
          this.submitOrder(id).then(() => {
            Toast.success("提交成功");
            this.newRepair = true;
            this.activeRepairLevels = 0;
            this.activeServiceTimeTypes = 0;
            this.activeRepairLevels = 0;
            this.query.serviceTime = "";
            this.query.surveyTime = "";
            this.query.description = "";
            this.imgs = [];
            this.imgsDisplay = [];
          });
        })
        .catch((res) => {
          Toast(res);
        });
    },
  },
};
</script>

<style src="@/assets/css/review/repair.css" scoped>
</style>

<style scoped>
.mine {
  position: fixed;
  bottom: 302px;
  right: 13px;
  width: 137px;
  height: 137px;
  background-image: url("../../../assets/images/user/mine.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>