/*
 * @Descripttion: 门店接口
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-12 14:39:34
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 22:00:09
 */
import request from "../request"

// 获取门店信息
export const getStoreInfo = function (data) {
    return request.get('/other/getStoreList', {
        params: data
    })
}